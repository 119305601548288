<template>
  <div id="app">
    <Navbar />
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './components/NavBar/Navbar.vue';

export default {
  components: {
    Navbar,
  },
};
</script>

<style>
/* Global styles here */
</style>
